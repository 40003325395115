import React from 'react'
import styled from 'styled-components'

const IconFootPrintDog = () => (
  <svg
    width="101"
    height="101"
    viewBox="0 0 101 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_219_3330)">
      <path
        d="M30.4343 52.502C34.6148 50.5689 36.0333 44.8066 33.6012 39.6285C31.1684 34.4448 25.8049 31.8168 21.6244 33.7499C17.4431 35.684 16.023 41.4484 18.4546 46.6293C20.8917 51.8031 26.252 54.4353 30.4325 52.5022L30.4343 52.502Z"
        fill="white"
      />
      <path
        d="M46.6774 38.6922C50.7013 37.7186 52.9572 32.8977 51.7161 27.9222C50.4753 22.9484 46.2125 19.6994 42.1849 20.6734C38.1629 21.6468 35.9038 26.4653 37.147 31.4425C38.387 36.4172 42.6543 39.6647 46.6774 38.6922Z"
        fill="white"
      />
      <path
        d="M62.2081 41.013C65.713 42.7678 70.288 40.6947 72.4234 36.3833C74.5649 32.0768 73.4538 27.1627 69.9527 25.4093C66.4499 23.6562 61.8768 25.7291 59.7367 30.0401C57.5972 34.3483 58.7073 39.2615 62.2081 41.013Z"
        fill="white"
      />
      <path
        d="M84.5609 46.8177C83.4426 43.5018 79.2162 41.9081 75.1223 43.2557C71.0222 44.5986 68.6072 48.381 69.7235 51.6953C70.838 55.0097 75.0628 56.6055 79.1577 55.2587C83.2592 53.9119 85.6742 50.1295 84.562 46.8185L84.5609 46.8177Z"
        fill="white"
      />
      <path
        d="M74.3565 73.8881C70.6017 78.2423 63.423 77.1648 58.5184 75.6402C54.9022 74.5177 51.641 73.1009 47.7877 73.1076C42.3368 73.1248 36.9933 76.2716 31.5536 75.6167C26.3763 74.9895 24.5892 70.242 24.4991 65.606C24.384 59.5413 30.641 58.5835 34.6219 55.788C37.1571 54.0079 38.9787 51.4156 40.5447 48.7846C42.0339 46.2866 43.6618 43.0229 46.6557 41.7742C54.3663 38.5627 58.7314 45.9494 61.8826 51.4544C63.4938 54.2732 65.6767 56.0878 68.455 57.7176C73.7682 60.8438 79.5545 67.8573 74.3565 73.8881Z"
        fill="white"
      />
    </g>
    <circle cx="50.2594" cy="50.5848" r="49.4535" stroke="white" />
    <defs>
      <clipPath id="clip0_219_3330">
        <rect
          width="67.0428"
          height="64.4137"
          fill="white"
          transform="matrix(-0.789403 -0.613876 -0.613876 0.789403 96.2693 45.731)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default IconFootPrintDog
